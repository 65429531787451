import parsePhoneNumber from "libphonenumber-js";
import { BACKEND_BASE_URL } from "@/utils/globalConstants.js";
import { fetchAndLog, fetchAndLogSensitively } from "@/utils/fetchAndLogToBackend.js";

export const baseUrl = BACKEND_BASE_URL;

export class Candidate {
  static listAllCandidatesInRegion(options) {
    let { regionId, limit, offset, role, distinctUser } = options;
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      window.clientLogger("No auth token found");
      throw new Error("No auth token found");
    }
    if (!limit) {
      limit = 10;
    }
    if (!offset) {
      offset = 0;
    }
    if(!role) {
      role = "all"
    }

    const url = `${baseUrl}/kartlegging/regions/${regionId}/candidates?offset=${offset}&limit=${limit}&role=${role}&distinct_user=${distinctUser}`;

    const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${authToken}`,
        },
      };

    return fetchAndLog(url, request);
  }

  static listAllCandidates(options) {
    let { limit, offset, distinctUser, from=null, to=null } = options;
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      throw new Error("No auth token found");
    }
    if (!limit) {
      limit = 10;
    }
    if (!offset) {
      offset = 0;
    }
    if(!distinctUser) {
      distinctUser = false;
    }

    const url = `${baseUrl}/kartlegging/candidates?offset=${offset}&limit=${limit}&distinct_user=${distinctUser}&from=${from}&to=${to}`;

    const request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    };

    return fetchAndLog(url, request);
  }

  static updateCandidate(options) {
    // eslint-disable-next-line
    const {
      test_access,
      phone,
      password,
      firstName,
      lastName,
      regionId,
      candidateId,
      token,
    } = options;
    // eslint-disable-next-line
    const authToken = localStorage.getItem("authToken") || token;
    if (!authToken) {
      window.clientLogger("No auth token found");
      throw new Error("No auth token found");
    }
    let phoneNumber = null;
    if(phone && phone.length)  {
      const newPhoneNumber = parsePhoneNumber(phone, "NO").formatInternational();
      phoneNumber = newPhoneNumber;
    }
    const payload = {
      test_access: test_access,
      phone_number: phoneNumber,
      password: password,
      first_name: firstName,
      last_name: lastName,
    };
    console.log(phoneNumber)
    Object.keys(payload).forEach((prop) => {
      if (payload[prop] === null) {
        delete payload[prop];
      }
    });

    const url = `${baseUrl}/kartlegging/regions/${regionId}/candidates/${candidateId}`;
    const request = {
      method: "PATCH",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    };

    if (password === null) {
      return fetchAndLog(url, request)
    } else {
        return fetchAndLogSensitively(url, request);
    }
}

  static getCandidate(options) {
    const { candidateId, regionId } = options;
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      throw new Error("No auth token found");
    }

    const url = `${baseUrl}/kartlegging/regions/${regionId}/candidates/${candidateId}`;

    const request = {
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    };

    return fetchAndLog(url, request);
  }

  static createNewCandidate(options) {
    const {
      regionId,
      access,
      phone,
      password,
      firstName,
      lastName,
      id,
      email,
      role,
    } = options;
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      window.clientLogger("No auth token found");
      throw new Error("No auth token found");
    }
    const phoneNumber = parsePhoneNumber(phone, "NO");
    const payload = {
      access: access,
      phone_number: phoneNumber.number,
      password: password,
      first_name: firstName,
      last_name: lastName,
      id: id,
      email: email,
      role: role,
    };

    Object.keys(payload).forEach((prop) => {
      if (!payload[prop]) {
        delete payload[prop];
      }
    });

    const url = `${baseUrl}/kartlegging/regions/${regionId}/candidates`;

    const request = {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    };

    return fetchAndLog(url, request);
  }

  static deleteCandidate(options) {
    const { regionId, candidateId } = options;

    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      window.clientLogger("No auth token found");
      throw new Error("No auth token found");
    }

    const url = `${baseUrl}/kartlegging/regions/${regionId}/candidates/${candidateId}`;

    const request = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    };

    return fetchAndLog(url, request);
  }
}
