<template>
  <router-view />
</template>

<script>
import { SQF_URL } from "@/utils/globalConstants.js";
export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Direktoratet for høyere utdanning og kompetanse";
      },
    },
  },
  mounted() {
    // Add SQF
    let sqfScript = document.createElement('script')
    sqfScript.setAttribute('src', SQF_URL)
    document.head.appendChild(sqfScript);

    // Add favicon
    let favIconTag = document.createElement('link');
    favIconTag.rel = "icon";
    favIconTag.href = "favicon.ico";
    document.head.appendChild(favIconTag);

    const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === "viewport") {
      const metaInfo = metas[i].getAttribute('content').concat(',user-scalable=no');
      metas[i].setAttribute('content', metaInfo)
    }
  }
  },
};
</script>

<style lang="css">
@import url("https://fonts.googleapis.com/css?family=Raleway:400,500&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/css/bootstrap.min.css");

* {
  font-family: "Roboto", sans-serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  height: 100%;
  background-color: #fafbfd !important;
}
</style>
