import { Statistics } from "@/services/statistics/statistics.service.js";

export default function getRegion({ commit }) {
    return Statistics.getRegion().then(resp => {
        commit("SET_REGION", resp)
    }).catch(err => {
        console.error("Error", err);
        commit("SET_REGION", {
          status: 500,
          detail: "Noe gikk galt",
        });
    });
 }