import { BACKEND_BASE_URL } from "@/utils/globalConstants.js";
import { fetchAndLog } from "@/utils/fetchAndLogToBackend.js";

export const baseUrl = BACKEND_BASE_URL;

export class Statistics {
  static filterRegionalModules(options) {
    let { to, from, regionId, limit, offset } = options;

    if(!limit) {
      limit = 10;
    }
    if(!offset) {
      offset = 0;
    }

    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      window.clientLogger("No auth token found");
      throw new Error("No auth token found");
    }

    let url = `${baseUrl}/kartlegging/regions/${regionId}/moduleassignments/?limit=${limit}&offset=${offset}`;

    if (to && !from) {
      url = `${baseUrl}/kartlegging/regions/${regionId}/moduleassignments/?to=${to}&limit=${limit}&offset=${offset}`;
    }

    if (!to && from) {
      url = `${baseUrl}/kartlegging/regions/${regionId}/moduleassignments/?from=${from}&limit=${limit}&offset=${offset}`;
    }

    if (to && from) {
      url = `${baseUrl}/kartlegging/regions/${regionId}/moduleassignments/?from=${from}&to=${to}&limit=${limit}&offset=${offset}`;
    }

    const request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    };

    return fetchAndLog(url, request);
  }

  static getRegionalStatistics(options) {
    const { regionId } = options;

    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      window.clientLogger("No auth token found");
      throw new Error("No auth token found");
    }

    return fetchAndLog(`${baseUrl}/kartlegging/regions/${regionId}/statistics/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    });
  }

  static getRegion(options = {}) {
    const { token } = options;

    // eslint-disable-next-line
    const authToken = localStorage.getItem("authToken") || token;
    if (!authToken) {
      window.clientLogger("No auth token found");
      throw new Error("No auth token found");
    }
    
    return fetchAndLog(`${baseUrl}/people/v1/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
      referrerPolicy: "origin-when-cross-origin",
      /* mode: 'no-cors', */
      credentials: "omit"
    });
  }

  static async getRegionalUserStatistics(options = {}) {
    const { token, regionId, candidateId } = options;

    // eslint-disable-next-line
    const authToken = localStorage.getItem("authToken") || token;

    if (!authToken) {
      window.clientLogger("No auth token found");
      throw new Error("No auth token found");
    }

    return fetchAndLog(
      `${baseUrl}/kartlegging/regions/${regionId}/statistics/${candidateId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${authToken}`,
        },
      }
    );
  }

  static getNationalStatistics(options = {}) {
    let { limit, offset, from, to, distinctUser } = options;

    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      window.clientLogger("No auth token found");
      throw new Error("No auth token found");
    }

    if (!offset) {
      offset = 0;
    }

    if (!limit) {
      limit = 10;
    }

    if (!distinctUser) {
      distinctUser = false;
    }

    let url = `${baseUrl}/kartlegging/module-distribution/`;
    if (from && to) {
      url = `${baseUrl}/kartlegging/module-distribution?from=${from}&to=${to}`;
    } else if(from && !to) {
      url = `${baseUrl}/kartlegging/module-distribution?from=${from}`;
    } else if(!from && to) {
      url = `${baseUrl}/kartlegging/module-distribution?to=${to}`;
    }

    const request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    };

    return fetchAndLog(url, request);
  }
}

export class StatisticsUtils {
  static unpopulatedStatistics = {
    "Modul 1": {
      "Tall og Algebra": { correct_answers: 0, incorrect_answers: 0 },
      "Maaling og Geometri": { correct_answers: 0, incorrect_answers: 0 },
      Oekonomi: { correct_answers: 0, incorrect_answers: 0 },
      "Statistikk, Kombinatorikk og Sannsynlighet": {
        correct_answers: 0,
        incorrect_answers: 0,
      },
      Funksjoner: { correct_answers: 0, incorrect_answers: 0 },
    },
    "Modul 2": {
      "Tall og Algebra": { correct_answers: 0, incorrect_answers: 0 },
      "Maaling og Geometri": { correct_answers: 0, incorrect_answers: 0 },
      Oekonomi: { correct_answers: 0, incorrect_answers: 0 },
      "Statistikk, Kombinatorikk og Sannsynlighet": {
        correct_answers: 0,
        incorrect_answers: 0,
      },
      Funksjoner: { correct_answers: 0, incorrect_answers: 0 },
    },
    "Modul 3": {
      "Tall og Algebra": { correct_answers: 0, incorrect_answers: 0 },
      "Maaling og Geometri": { correct_answers: 0, incorrect_answers: 6 },
      Oekonomi: { correct_answers: 0, incorrect_answers: 0 },
      "Statistikk, Kombinatorikk og Sannsynlighet": {
        correct_answers: 0,
        incorrect_answers: 0,
      },
      Funksjoner: { correct_answers: 0, incorrect_answers: 0 },
    },
    "Modul 4": {
      "Tall og Algebra": { correct_answers: 0, incorrect_answers: 0 },
      "Maaling og Geometri": { correct_answers: 0, incorrect_answers: 0 },
      Oekonomi: { correct_answers: 0, incorrect_answers: 0 },
      "Statistikk, Kombinatorikk og Sannsynlighet": {
        correct_answers: 0,
        incorrect_answers: 0,
      },
      Funksjoner: { correct_answers: 0, incorrect_answers: 0 },
    },
    total_correct: 0,
    total_incorrect: 0,
  };
  static mockStatistics = {
    "Modul 1": {
      "Tall og Algebra": { correct_answers: 2, incorrect_answers: 1 },
      "Maaling og Geometri": { correct_answers: 3, incorrect_answers: 1 },
      Oekonomi: { correct_answers: 1, incorrect_answers: 3 },
      "Statistikk, Kombinatorikk og Sannsynlighet": {
        correct_answers: 3,
        incorrect_answers: 1,
      },
      Funksjoner: { correct_answers: 0, incorrect_answers: 0 },
    },
    "Modul 2": {
      "Tall og Algebra": { correct_answers: 1, incorrect_answers: 2 },
      "Maaling og Geometri": { correct_answers: 2, incorrect_answers: 0 },
      Oekonomi: { correct_answers: 4, incorrect_answers: 0 },
      "Statistikk, Kombinatorikk og Sannsynlighet": {
        correct_answers: 5,
        incorrect_answers: 0,
      },
      Funksjoner: { correct_answers: 0, incorrect_answers: 0 },
    },
    "Modul 3": {
      "Tall og Algebra": { correct_answers: 6, incorrect_answers: 0 },
      "Maaling og Geometri": { correct_answers: 0, incorrect_answers: 6 },
      Oekonomi: { correct_answers: 3, incorrect_answers: 1 },
      "Statistikk, Kombinatorikk og Sannsynlighet": {
        correct_answers: 2,
        incorrect_answers: 3,
      },
      Funksjoner: { correct_answers: 0, incorrect_answers: 0 },
    },
    "Modul 4": {
      "Tall og Algebra": { correct_answers: 0, incorrect_answers: 1 },
      "Maaling og Geometri": { correct_answers: 0, incorrect_answers: 1 },
      Oekonomi: { correct_answers: 1, incorrect_answers: 1 },
      "Statistikk, Kombinatorikk og Sannsynlighet": {
        correct_answers: 0,
        incorrect_answers: 1,
      },
      Funksjoner: { correct_answers: 1, incorrect_answers: 1 },
    },
    total_correct: 32,
    total_incorrect: 22,
  };
  static parseUserOrRegionStatistics(response) {
    // corresponds to the table to display and the names of what is returned from the db
    let parsedStatistics = Object.assign(this.unpopulatedStatistics);

    let total_correct = 0;
    let total_incorrect = 0;

    response.answers.map((mod) => {
      parsedStatistics[mod.Modul.name][mod.Hovedomraade.name] = {
        correct_answers: mod.CA,
        incorrect_answers: mod.IA,
      };

      total_correct += mod.CA;
      total_incorrect += mod.IA;
    });

    parsedStatistics.total_correct = total_correct;
    parsedStatistics.total_incorrect = total_incorrect;

    return parsedStatistics;
  }
}
