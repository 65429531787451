import SET_CANDIDATE from "./setCandidate";
import SET_DETAILED_CLASSROOM_INFORMATION from "./setDetailedClassRoomInformation";
import SET_STUDENT_PANEL from "./setStudentPanel";
import SET_STUDENT_PANEL_META_DATA from "./setStudentPanelMetaData";
import SET_START_TEST_TUTOMAT from "./setStartTestTutomat";
import SET_STOP_TEST_TUTOMAT from "./setStopTestTutomat";

export default {
    SET_CANDIDATE,
    SET_DETAILED_CLASSROOM_INFORMATION,
    SET_STUDENT_PANEL,
    SET_STUDENT_PANEL_META_DATA,
    SET_START_TEST_TUTOMAT,
    SET_STOP_TEST_TUTOMAT
}