import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "candidate-login" */ "../views/LoginPage.vue"),
    meta: {
      title: "Logg inn",
    },
  },
  {
    path: "/nationally-dashboard",
    name: "NationallyDashboard",
    component: () =>
      import(
        /* webpackChunkName: "nationally-dashboard" */ "../views/NationallyDashboard.vue"
      ),
    meta: {
      title: "Nasjonal hovedside",
      requiresAuth: true,
      is_national: true,
    },
  },
  {
    path: "/candidate-dashboard",
    name: "CandidateDashboard",
    component: () =>
      import(
        /* webpackChunkName: "candidate-dashboard" */ "../views/CandidateDashboard.vue"
      ),
    meta: {
      title: "Student hovedside",
      requiresAuth: true,
      is_candidate: true,
    },
  },
  {
    path: "/admin-dashboard",
    name: "AdminDashboard",
    component: () =>
      import(
        /* webpackChunkName: "admin-dashboard" */ "../views/AdminDashboard.vue"
      ),
    meta: {
      title: "Lærer hovedside",
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: "/assignments",
    name: "Assignments",
    component: () =>
      import(
        /* webpackChunkName: "admin-assignments" */ "../views/AdminAssignments.vue"
      ),
    meta: {
      title: "Lærer Oppgaver",
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: "/statistics",
    name: "AdminStatistics",
    component: () =>
      import(
        /* webpackChunkName: "admin-statistics" */ "../views/AdminStatistics.vue"
      ),
    meta: {
      title: "Lærer statistikk",
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: "/users",
    name: "AdminUsers",
    component: () =>
      import(/* webpackChunkName: "admin-users" */ "../views/AdminUsers.vue"),
    meta: { title: "Lærer brukerliste", requiresAuth: true, is_admin: true },
  },
  {
    path: "/user-profile",
    name: "AdminMyUser",
    component: () =>
      import(
        /* webpackChunkName: "admin-my-profile" */ "../views/AdminMyProfile.vue"
      ),
    meta: {
      title: "Lærer min side",
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: "/edit-user-nationally",
    name: "EditUserNationally",
    component: () =>
      import(
        /* webpackChunkName: "edit-user-nationally" */ "../views/EditUserNationally.vue"
      ),
    meta: { title: "Endre bruker" },
  },
  {
    path: "/test-summary",
    name: "TestSummary",
    component: () =>
      import(/* webpackChunkName: "test-summary" */ "../views/TestSummary.vue"),
    meta: { title: "Test sammendrag" },
  },
  {
    path: "/test-finished",
    name: "TestFinished",
    component: () =>
      import(
        /* webpackChunkName: "test-finished" */ "../views/TestFinished.vue"
      ),
    meta: { title: "Ferdig test" },
  },
  {
    path: "/exampletest-done",
    name: "ExampleTestDone",
    component: () =>
      import(
        /* webpackChunkName: "test-done" */ "../views/ExampleTestFinished.vue"
      ),
    meta: { title: "Eksempeltest ferdig" },
  },
  {
    path: "/test",
    name: "TestPage",
    component: () =>
      import(/* webpackChunkName: "test-page" */ "../views/TestPage.vue"),
    props: true,
    meta: { title: "Test side" },
  },
  {
    path: "/watch-test-done",
    name: "WatchTestDone",
    component: () =>
      import(
        /* webpackChunkName: "watch-test-done-page" */ "../views/WatchTestDone.vue"
      ),
    meta: { title: "Vis test besvarelse" },
  },
  {
    path: "/teaching-analysis",
    name: "TeachingAnalysis",
    component: () =>
      import(
        /* webpackChunkName: "teaching-analysis-page" */ "../views/TeachingAnalysis.vue"
      ),
    meta: { title: "Læringsanalyse" },
  },
  {
    path: "/example-test",
    name: "ExampleTestPage",
    component: () =>
      import(
        /* webpackChunkName: "example-test-page" */ "../views/ExampleTestPage.vue"
      ),
    props: true,
    meta: { title: "Eksempeltest" },
  },
  {
    path: "/forgot-password",
    name: "InputPhoneNumber",
    component: () =>
      import(
        /* webpackChunkName: "input-phone-number" */ "../views/forgottenPassword/InputPhoneNumber.vue"
      ),
    meta: { title: "Glemt passord", isLoginFlow: true },
  },
  {
    path: "/code-verification",
    name: "InputVerification",
    component: () =>
      import(
        /* webpackChunkName: "input-verification-number" */ "../views/forgottenPassword/InputVerificationCode.vue"
      ),
    meta: { title: "Verifiser tilsendt kode", isLoginFlow: true },
  },
  {
    path: "/set-password",
    name: "UpdateUser",
    component: () =>
      import(
        /* webpackChunkName: "update-user" */ "../views/forgottenPassword/UpdatePasswordUser.vue"
      ),
    meta: { title: "Sett passord", isLoginFlow: true },
  },

  {
    path: "/create-new-user",
    name: "InputPhoneCreateNewUser",
    component: () =>
      import(
        /* webpackChunkName: "input-phone-number" */ "../views/forgottenPassword/InputPhoneNumber.vue"
      ),
    meta: { title: "Opprett ny bruker", isLoginFlow: true },
  },
  {
    path: "/quiz-walkthrough",
    name: "QuizWalkthrough",
    component: () =>
      import(
        /* webpackChunkName: "quiz-walkthrough" */ "../views/QuizWalkthrough.vue"
      ),
    meta: { title: "Gå igjennom test" },
  },
  {
    path: "/:catchAll(.*)",
    component: () =>
      import(/* webpackChunkName: "candidate-login" */ "../views/LoginPage.vue"),
    name: "NotFound",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: "history",
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.isLoginFlow)) {
    next();
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("authToken") == null) {
      next({
        name: "Login",
      });
    } else {
      let user = localStorage.getItem("role");
      if (to.matched.some((record) => record.meta.is_admin)) {
        if (user == "teacher" || user == "kompetansenorge-teacher") {
          next();
        } else {
          return;
        }
      } else if (to.matched.some((record) => record.meta.is_national)) {
        let user = localStorage.getItem("role");
        if (to.matched.some((record) => record.meta.is_national)) {
          if (user == "nasjonal") {
            next();
          } else {
            return;
          }
        }
      } else if (to.matched.some((record) => record.meta.is_candidate)) {
        let user = localStorage.getItem("role");
        if (to.matched.some((record) => record.meta.is_candidate)) {
          if (user == "student") {
            next();
          } else {
            return;
          }
        }
      } else {
        next();
      }
    }
  } else if (to.name === "Login" && localStorage.getItem("authToken") != null) {
    let user = localStorage.getItem("role");
    if (user === "student") {
      next({
        name: "CandidateDashboard",
      });
    } else if (user === "nasjonal") {
      next({
        name: "NationallyDashboard",
      });
    } else if (user === "teacher") {
      next({
        name: "AdminDashboard",
      });
    } else {
      next({
        name: "Login",
      });
    }
  } else {
    if (to.matched.some((record) => record.meta.is_candidate)) {
      let user = localStorage.getItem("role");
      if (user == "teacher" || user == "kompetansenorge-teacher") {
        next({
          name: "Login",
        });
      } else if (user == "nasjonal") {
        next({
          name: "Login",
        });
      } else {
        next();
      }
    }
    next();
  }
});

export default router;
