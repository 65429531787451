import getCandidate from "./getCandidate";
import getDetailedClassRoomInformation from "./getDetailedClassRoomInformation";
import getStudentPanel from "./getStudentPanel";
import getStudentPanelMetaData from "./getStudentPanelMetaData";
import getStartTestTutomat from "./getStartTestTutomat";
import getStopTestTutomat from "./getStopTestTutomat";

export default {
    getCandidate,
    getDetailedClassRoomInformation,
    getStudentPanel,
    getStudentPanelMetaData,
    getStartTestTutomat,
    getStopTestTutomat
}