import actions from "./actions/index";
import getters from "./getters/index";
import mutations from "./mutations/index";
import state from "./state/index";

export default {
    namespaced: true,
    state: {
        ...state
    },
    mutations: {
        ...mutations
    },
    actions: {
        ...actions
    },
    getters: {
        ...getters
    }
}
