import { createStore } from "vuex"
import modules from "./modules/index";

const store = createStore({
    state: {},
    modules: {
        ...modules
    },
});


export default store;