
import { BACKEND_BASE_URL } from "@/utils/globalConstants.js";

const logBaseUrl = BACKEND_BASE_URL;

class LoggerService {
    static async log(message) {
        const resp = await fetch(`${logBaseUrl}/log/`, {
          method: "POST",
          body: message,
          headers: {
            "Content-Type": "application/json",
          },
        });
        const jsonResp = await resp.json();
        return jsonResp;
      }
}
export default async function clientLogger(message) {
    const authToken = localStorage.getItem("authToken") || "";
    const payloadMessage = message || "";

    const time = new Date();

    const payload = JSON.stringify({
        entry: {
            time: time.toLocaleString("no-NO"),
            message: payloadMessage,
            token: authToken
        }
    })
    const logResponse = await LoggerService.log(payload);
    return logResponse;
}

export async function sensitiveEndpointLogger(endpoint, response, status) {
    return clientLogger({
        endpoint: endpoint,
        response: response,
        status: status,
    });
}

export async function endpointLogger(endpoint, request, response, status) {
    return clientLogger({
        endpoint: endpoint,
        response: response,
        status: status,
        request: request,
    });
}

function logToWebsocket(user, tutomat, message) {
    return clientLogger({
        user: user,
        tutomat: tutomat,
        message: message
    });
}


export const logConnectedToWebsocket = (user, tutomat) => {
    return logToWebsocket(user, tutomat, "opened test page")
}

export const logDisconnectedFromWebsocket = (user, tutomat) => {
    return logToWebsocket(user, tutomat, "closed test page");
}

export const logWebsocketError = (user, tutomat, error) => {
    return logToWebsocket(user, tutomat, `error in test page websocket: ${error}`);
}