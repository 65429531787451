import { ClassRoom } from "@/services/classRoom/classRoom.service.js";

export default function getDetailedClassRoomInformation({ commit }, payload) {
    return ClassRoom.getDetailedClassRoomInformation(
        {
          classRoomId: payload.classRoomId,
          candidateId: payload.candidateId,
        }
      ).then(resp => {
        commit("SET_DETAILED_CLASSROOM_INFORMATION", resp)
    }).catch(err => {
        console.error("Error", err);
        commit("SET_DETAILED_CLASSROOM_INFORMATION", {
          status: 500,
          detail: "Noe gikk galt",
        });
    });
 }