import { Candidate } from "@/services/candidate/candidate.service.js";

export default function SetAllUsers({ commit }) {

    return Candidate.listAllCandidatesInRegion({}).then(resp => {
        commit("SET_ALL_USERS", resp.data)
    }).catch(err => {
        console.log(err);
        commit("SET_ALL_USERS", {
          status: 500,
          detail: "Noe gikk galt",
        });
    });
 }