import { Candidate } from "@/services/candidate/candidate.service.js";

export default function updateCandidate({ commit }, payload) {
    return Candidate.updateCandidate({
        regionId: payload.regionId,
        candidateId: payload.candidateId,
        access: payload.access,
        phone: payload.phone,
        password: payload.password,
        firstName: payload.firstName,
        lastName: payload.lastName
      }).then(resp => {
        commit("SET_UPDATE_CANDIDATE", resp)
    }).catch(err => {
        console.error("Error", err);
        commit("SET_UPDATE_CANDIDATE", {
          status: 500,
          detail: "Noe gikk galt",
        });
    });
 }