import { ClassRoom } from "@/services/classRoom/classRoom.service.js";

export default function getStopTestTutomat({ commit }, payload) {
    return ClassRoom.startTestTutomat({
      classRoomId: payload.classRoomId,
      candidateId: payload.candidateId,
      chainContextId: payload.chainContextId,
      payload: payload.body
    }).then(resp => {
        commit("SET_STOP_TEST_TUTOMAT", resp)
    }).catch(err => {
        console.error("Error", err);
        commit("SET_STOP_TEST_TUTOMAT", {
          status: 500,
          detail: "Noe gikk galt",
        });
    });
 }