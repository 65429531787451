import { BACKEND_BASE_URL } from "@/utils/globalConstants.js";
import { fetchAndLog } from "@/utils/fetchAndLogToBackend.js";

export const baseUrl = BACKEND_BASE_URL;

export class ClassRoom {
  static getDetailedClassRoomInformation(options) {
    const { classRoomId, candidateId } = options;
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      window.clientLogger("No auth token found");
      throw new Error("No auth token found");
    }

    const url = `${baseUrl}/adapt/v1/learning/users/${candidateId}/classrooms/${classRoomId}`;

    const request = {
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    };

    return fetchAndLog(url, request);
  }

  static getStudentPanel(options) {
    const { classRoomId, candidateId, cpId, rootId } = options;
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      window.clientLogger("No auth token found");
      throw new Error("No auth token found");
    }

    const url = `${baseUrl}/adapt/v1/learning/users/${candidateId}/classrooms/${classRoomId}/packages/${cpId}/node/${rootId}/studentpanel`;

    const request = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    };

    return fetchAndLog(url, request);
  }

  static stopTest(options) {
    const { classRoomId, candidateId, rootId, payload } = options;
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      window.clientLogger("No auth token found");
      throw new Error("No auth token found");
    }

    const url = `${baseUrl}/adapt/v1/learning/users/${candidateId}/classrooms/${classRoomId}/chaincontexts/${rootId}/statements`;

    const request = {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    };

    return fetchAndLog(url, request);
  }

  static getStudentPanelMetaData(options) {
    const { classRoomId, candidateId, cpId, tocRoot } = options;
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      window.clientLogger("No auth token found");
      throw new Error("No auth token found");
    }

    const url = `${baseUrl}/adapt/v1/learning/users/${candidateId}/classrooms/${classRoomId}/packages/${cpId}/node/${tocRoot}/studentpanel`;

    const request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    };

    return fetchAndLog(url, request);
  }

  static startTestTutomat(options) {
    const { classRoomId, candidateId, chainContextId, payload, token } = options;
    // eslint-disable-next-line
    const authToken = localStorage.getItem("authToken") || token;
    if (!authToken) {
      window.clientLogger("No auth token found");
      throw new Error("No auth token found");
    }

    const url = `${baseUrl}/adapt/v1/learning/users/${candidateId}/classrooms/${classRoomId}/chaincontexts/${chainContextId}/statements`;

    const request = {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    };
    
    return fetchAndLog(url, request);
  }

  static getExampleTest() {
    const url = `${baseUrl}/kartlegging/exampletest`;

    const request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    return fetchAndLog(url, request);
  }
}