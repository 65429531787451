import { ClassRoom } from "@/services/classRoom/classRoom.service.js";

export default function getStudentPanel({ commit }, payload) {
    return ClassRoom.getStudentPanel({
        classRoomId: payload.classRoomId,
        candidateId: payload.candidateId,
        rootId: payload.rootId,
        cpId: payload.cpId,
      }).then(resp => {
        commit("SET_STUDENT_PANEL", resp)
    }).catch(err => {
        console.error("Error", err);
        commit("SET_STUDENT_PANEL", {
          status: 500,
          detail: "Noe gikk galt",
        });
    });
 }