import { ClassRoom } from "@/services/classRoom/classRoom.service.js";

export default async function getStudentPanel({ commit }, payload) {
    return ClassRoom.getStudentPanelMetaData({
        classRoomId: payload.classRoomId,
        candidateId: payload.candidateId,
        tocRoot: payload.tocRoot,
        cpId: payload.cpId,
      }).then(resp => {
        commit("SET_STUDENT_PANEL_META_DATA", resp)
      }).catch(err => {
        console.error("Error", err);
        commit("SET_STUDENT_PANEL_META_DATA", {
          status: 500,
          detail: "Noe gikk galt",
        });
      })
 }