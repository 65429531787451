import { Candidate } from "@/services/candidate/candidate.service.js";

export default function getCandidate({ commit }, payload) {
    return Candidate.getCandidate({
        regionId: payload.regionId,
        candidateId: payload.candidateId,
      }).then(resp => {
        commit("SET_CANDIDATE", resp)
    }).catch(err => {
        console.error(err);
        commit("SET_CANDIDATE", {
            status: 500,
            detail: "Noe gikk galt",
          });
    });
 }
