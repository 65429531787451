import store from "./state/index";
import router from "./router"
import VueApexCharts from "vue3-apexcharts";
import { createApp,h } from 'vue'
import App from './app.vue'
import Toaster from "@meforma/vue-toaster";
import clientLogger from './utils/clientLogger';


try {
    window.$ = window.jQuery = require("jquery");
    require("bootstrap")
} catch(err) {
    console.error("Error bootstraping jquery", err);
}


window.clientLogger = clientLogger;


// eslint-disable-next-line
const app  = createApp({
    render: ()=>h(App),
})

// Oppgaver tab for teacher
app.config.globalProperties.$ASSIGNMENTS_TAB = process.env.ASSIGNMENT_OPPGAVE_FLAG === 'true';

app.use(store).use(VueApexCharts).use(Toaster).use(router).mount("#app");
