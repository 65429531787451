import { endpointLogger, sensitiveEndpointLogger } from './clientLogger';
import { unsuccessfulStatus } from './httpStatus';

// todo: create a method fetchWrapper that both fetchAndLog and fetchAndLogSensitively calls?

export const STANDARD_API_ERROR_DETAIL = "Teknisk feil. Vennligst kontakt Studix support";

const STANDARD_API_ERROR_JSON = {
        status: 500,
        detail: STANDARD_API_ERROR_DETAIL,
    };

const EXCEPTION_LOG_STATUS = "EXCEPTION";


function parseResponseStatus(response) {
    if (response.status === 204) {
        // no data in response
        return { detail: "empty response" };
    } else if (response.status === 500) {
        // currently backend API returns a string instead of JSON on 500 internal server errors
        return { detail: STANDARD_API_ERROR_DETAIL, log: "internal server error - todo: handle this differently?" };
    } else {
        return response.json();
    }
}

function parseStructure(statusParsedResponse, status) {
    let response = statusParsedResponse;

    if (typeof statusParsedResponse !== "object") {
      // sometimes statusParsedResponse will be a string because
      // the API sometimes returns strings instead of JSONS
      response = {
        detail: statusParsedResponse,
      }
    }

    response.status = status;

    return response;
}

function addDetailIfMissing(responseData, status) {
    if (unsuccessfulStatus(status) && !responseData.detail) {
        responseData.detail = STANDARD_API_ERROR_DETAIL;
    }
    return responseData;
}


export async function fetchAndLog(endpoint, request) {
    return fetch(
        endpoint, request
    )
      .then((res) => Promise.all([parseResponseStatus(res), res.status]))

      .then(([response, status]) => {
        return parseStructure(response, status);
      })

      .then((response) => {
        if (response && unsuccessfulStatus(response.status)) {
            // log to backend
            endpointLogger(endpoint, request, response, response.status);
        }
        
        const responseDataWithDetail = addDetailIfMissing(response, response.status);

        return responseDataWithDetail;
      })

      .catch((err) => {
        console.log(err);
        endpointLogger(endpoint, request, err, EXCEPTION_LOG_STATUS);

        return STANDARD_API_ERROR_JSON;
      });
}


// use this when the request has sensitive information like password
export async function fetchAndLogSensitively(endpoint, request) {
    return fetch(
        endpoint, request
    )
      .then((res) => Promise.all([parseResponseStatus(res), res.status]))

      .then(([response, status]) => {
        return parseStructure(response, status);
      })

      .then((response) => {
        if (response && unsuccessfulStatus(response.status)) {
            // log to backend
            sensitiveEndpointLogger(endpoint, response, response.status);
        }

        const responseDataWithDetail = addDetailIfMissing(response, response.status);

        return responseDataWithDetail;
      })
      .catch((err) => {
        console.log(err);
        return STANDARD_API_ERROR_JSON;
      });
}